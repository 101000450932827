<template>
    <div class="nft_container overflow-y-auto">
        <div class="pa-20">

            <div class="flex-row justify-center align_items_center mb-30" style="height: 30px;">
                <p class="size-px-20 color-white font-weight-600">MAFIA CARD</p>
            </div>
            <div class="container justify-center">
                <div class="row">
                    <div
                        class="nft_item"
                        v-for="(item, index) in cartl_nft_purchase_list"
                        :key="'item_' + index"
                        :style="'background-image: url(' + item.cartl_nft_img_url + ');'"
                    >
                        <div class="tit">
                            <img :src="item.cartl_nft_catgy_img_url" alt="MAFIA CARD">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="nft_popup">

            <div class="mt-auto size-px-14 text-center pa-20 pt-30 bg-blue-02 radius-t-20">
                <div class="size-px-16 font-weight-700">BUY
                    <span style="color: #DC505C">{{ purchase_info.last_payment_amount_quantity | makeComma(8) }}{{ purchase_info.payment_money_name }}</span>
                </div>
                <div class="mt-20 color-gray">
                    {{  $language.launchpad.txt_nft_result }}
                </div>
                <div class="btn_area mt-20">
                    <button
                        class="btn_fill_blue btn_l"
                        @click="goBack"
                    >{{ $language.common.ok }}
                    </button>
                </div>
                <div class="mt-20" @click="$router.push({ name: 'nft'})">{{  $language.nft.nft_list }} <i class="icon icon-arrow-right"></i></div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PurchaseNft'
    , props: ['user']
    , data: function () {
        return {
            program: {
                name: 'MAFIA CARD'
                , not_header: true
                , not_footer: true
            }
            , purchase_info: []
            , cartl_nft_purchase_list: []
        }
    }
    , methods: {
        goBack: function () {
            this.$router.back()
        }
    }

    , created() {
        this.$emit('onLoad', this.program)
        console.log(this.$route.params.items, 'nft params')
        console.log(this.user, 'nft params')
        this.purchase_info = this.$route.params.items
        this.cartl_nft_purchase_list = this.$route.params.items.cartl_nft_purchase_list

    }
}
</script>
<style scoped>
.nft_container {
    position: relative;
    height: 100%;
    width: 100%;
    background: #08080D;
    color: white;
    padding-bottom: 200px;
}

.nft_popup {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
}

.nft_item {
    width: 260px;
    height: 260px;
    background-size: cover;
    background-position: center center;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 30px;
}
</style>